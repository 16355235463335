<template>
    <Component :is="layout" :flushWithHeader="true" :topMargin="headerMargin">
        <slot
            :rows="rows"
            :contentTitle="contentTitle"
            :contentStartTime="contentStartTime"
            :contentEndTime="contentEndTime"
        ></slot>
    </Component>
</template>

<script setup>
import { cloneDeep } from 'lodash-es';

import { DISCOVER_MODULE_NAME } from '~coreModules/discover/js/discover-store';
import { useGetters, useState } from '~coreModules/core/js/composables/vuexHelpers';

import useAnalyticsPageView from '~coreModules/analytics/js/composables/use-analytics-pageview';

defineProps({
    layout: {
        type: [Function, Object],
        required: true,
    },
});

useAnalyticsPageView();

const { headerMargin } = useGetters(DISCOVER_MODULE_NAME, ['headerMargin']);
const {
    contentTitle,
    contentStartTime,
    contentEndTime,
    rows,
} = useState(DISCOVER_MODULE_NAME, {
    contentTitle: state => state.contentPage?.title ?? '',
    contentStartTime: state => state.contentPage?.startTime ?? '',
    contentEndTime: state => state.contentPage?.endTime ?? '',
    rows: state => cloneDeep(state.contentPage?.rows ?? []),
});
</script>

<style lang="scss">

</style>
