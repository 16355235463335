<template>
    <BaseDiscoverPageContainer :layout="RawLayout">
        <template #default="{ rows, contentTitle, contentStartTime, contentEndTime }">
            <ContentfulContentBlock
                :contentRows="rows"
                :contentTitle="contentTitle"
                :contentStartTime="contentStartTime"
                :contentEndTime="contentEndTime"
            />
        </template>
    </BaseDiscoverPageContainer>
</template>

<!-- Seperate script tag needed for custom fetchData and vuex logic, to be used in entry-server and entry-client -->
<script>
import { DISCOVER_MODULE_NAME, FETCH_DISCOVER_CONTENT } from '~coreModules/discover/js/discover-store';

async function fetchData({ store, route }) {
    const { slug } = route.params;
    const { isLegalPage = false } = route.meta;

    return store.dispatch(`${DISCOVER_MODULE_NAME}/${FETCH_DISCOVER_CONTENT}`,
        { slug, isLegalPage });
}

export default {
    fetchData,
};
</script>

<script setup>
/* eslint-disable import/first */
import { markRaw } from 'vue';

import discoverMetaMixin from '~coreModules/discover/js/discover-meta';
import metaTagsMixin from '~modules/core/js/seo/meta-tags';

import BaseDiscoverPageContainer from '~coreModules/discover/components/BaseDiscoverPageContainer.vue';
import ContentfulContentBlock from '~modules/contentful/components/ContentfulContentBlock.vue';
import Layout from '~modules/core/components/layouts/Layout.vue';
/* eslint-enable import/first */

// NU_TODO: https://urbnit.atlassian.net/browse/TYP-26682
// Remove below when we update to vue-eslint-parser v9.0.0 or later
/* eslint-disable no-undef */
defineOptions({
    mixins: [discoverMetaMixin, metaTagsMixin],
});

const RawLayout = markRaw(Layout);

</script>

<style lang="scss">

</style>
